import React from 'react';
import { graphql } from "gatsby";

import SEO from "../../../components/en/seo/Seo";
import Header from "../../../components/en/headers/Header";
import Footer from "../../../components/en/layouts/Footer";
import GoodToKnow from "../../../components/en/main-row/GoodToKnow";
import CallToAction from "../../../components/en/layouts/CallToAction";
import MainRowHeader from "../../../components/en/main-row/MainRowHeader";
import MainRowDescription from "../../../components/en/main-row/MainRowDescription";
import StaticMainRowGallery from "../../../components/en/main-row/StaticMainRowGallery";

function MainPit({data}) {
    return (
        <>
            <SEO
                title="The Main Row"
                description="By visiting the Main pit of the Sitarjevec Litija mine, visitors will be so close to nature and the endless creation of eternal natural processes that they will not only observe it, but will feel part of the process itself. Because of the experience of light and darkness, the silence and sounds in the mining underground, the smell of iron and the movement of air - perhaps more intensely than ever before."
            />
            <Header />
            <MainRowHeader/>
            <MainRowDescription/>
            <GoodToKnow/>
            <StaticMainRowGallery imagesData={data.source.edges}/>
            <CallToAction/>
            <Footer/>
        </>
    );
}

export const pageQuery = graphql`
    query ImagesForMainRowGalleryInEnglish {
        source: allFile(filter: {relativePath: {regex: "rudnik/glavni-rudnik-galerija/"}}) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 200
                            height: 200
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default MainPit;