import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function MainRowDescription(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 col-xl-6 order-1 mt-4 order-lg-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/glavni-rov-sitarjevskega-rudnika.jpg"
                                    alt="The main tunnel in the Sitarjevec Mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="about-inner inner p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Guided tours</h2>
                                    <p className="description">From 22 April 2021 forward, the lower part of the Sitarjevec Litija Mine is opened to visitors. Tourists will descend to the Main pit along a completely newly arranged entrance. They will find themselves in an authentic mining environment. It is partly opened both in height and in depth, into comfortably widened the mine shafts.
                                    </p>
                                    <p className="description">On the colourful limonite mud, they will walk past even more interesting minerals on the walls to the famous, largest discovered limonite dripstone in the mine. Along the way, they will admire the heritage of industrial architecture, and at the end they will be rewarded with a beautiful view of limonite spaghetti - extremely thin dripstone structures.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--160">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-12 col-lg-10 col-xl-9 col-md-12 text-center text-md-left">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">The magical world of Litija’s underworld</h2>
                                    <p className="description">The underground of Sitarjevec Litija mine is a very venerable area. Visitors will observe the constant and intense formation of the famous limonite dripstones and limonite mud very closely. That makes the Sitarjevec mine so very colorful and unique.  Gypsum crystallization is also ongoing. The fragile needle-like crystals that form on the ceiling of the abandoned mine shafts inspire us with the elegance of light reflections, as do the water droplets that enchant with pearly light reflections.
                                    </p>
                                    <p className="description">
                                        By visiting the Main pit of the Sitarjevec Litija mine, visitors will be so close to nature and the endless creation of eternal natural processes that they will not only observe it, but will feel part of the process itself. Because of the experience of light and darkness, the silence and sounds in the mining underground, the smell of iron and the movement of air - perhaps more intensely than ever before.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainRowDescription;