import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

function GoodToKnow(props) {
    return (
        <div className="rn-about-area ptb--120 bg_color--3">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="">
                            <div className="section-title">
                                <h2 className="title">It is good to know</h2>
                            </div>
                            <div className="mt--30">
                                <ul className="list-style--1">
                                    <li>
                                        <FiCheck />
                                        Viewing is possible only with prior  - <Link to="/en/contact-and-information">reservation.</Link>
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Visits are allowed to people from 10 to 70 years of age, or to persons with good mobility and physical condition.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The visitor receives protective equipment: a helmet with a headlamp, a safety clasp and belt, protective clothing and footwear.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Viewing is possible in groups of 4 to 8 people.
                                    </li>
                                    <li>
                                        <FiCheck />
                                        The tour of the mine lasts up to two hours.
                                    </li>
                                    <li>
                                        <FiCheck />
                                        The pit has a constant temperature of 10 °C and 100 % humidity.
                                    </li>
                                </ul>
                            </div>

                            <div className="mt--40">
                                <Link to="/en/contact-and-information" className="btn-default size-md">Book a Tour</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail">
                            <StaticImage
                                src="../../../assets/images/rudnik/pohod-v-glavnem-rovu-rudnika.jpg"
                                alt="Stalactite in the Sitarjevec mine"
                                placeholder="blurred"
                                imgClassName="img-fluid w-100"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default GoodToKnow;